<template>
  <BlokkliProvider v-slot="{ entity }" v-bind="blokkliProps" :entity="props">
    <div :class="hero && 'mt-[-88px]'">
      <div v-if="hero">
        <MediaImage
          v-blokkli-droppable:field_hero_image="{
            entityType: 'media',
            entityBundles: ['image'],
            cardinality: 1,
          }"
          hide-caption
          v-bind="entity?.hero || hero"
          :image-style="imageStyle"
          class="hero-image"
        />
      </div>
      <NuxtPageDependency>
        <Breadcrumb v-if="showBreadcrumb" :links="breadcrumb" />
      </NuxtPageDependency>
      <Container>
        <h1
          v-blokkli-editable:title
          class="text-4xl lg:text-7xl pb-5 lg:pb-12 hyphens-none"
        >
          {{ entity?.title || title }}
        </h1>
      </Container>
      <div v-if="lead" class="grid-container">
        <div class="grid-container-8 pb-10">
          <div
            v-blokkli-editable:field_lead="{ type: 'frame' }"
            class="text-xl md:text-2xl"
            v-html="lead"
          />
        </div>
      </div>
      <div>
        <BlokkliField :list="paragraphs" name="field_paragraphs" />
      </div>
    </div>
  </BlokkliProvider>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'

const props = defineProps<{
  uuid: string
  title?: string
  lead?: string
  businessArea?: NodePageFragment['businessArea']
  paragraphs?: NodePageFragment['paragraphs']
  canEdit?: NodePageFragment['canEdit']
  hero?: NodePageFragment['hero']
  blokkliProps: NodePageFragment['blokkliProps']
}>()

const imageStyle = defineImageStyle({
  type: 'pictures',
  pictures: {
    xs: {
      width: 728,
      aspectRatio: 1,
    },
    sm: {
      width: 1024,
      aspectRatio: 16 / 6,
    },
    md: {
      width: 1400,
      aspectRatio: 16 / 6,
    },
    lg: {
      width: 2600,
      aspectRatio: 16 / 6,
    },
  },
})

const route = useRoute()
const showBreadcrumb = computed(() => route.meta.hideBreadcrumb !== true)
const breadcrumb = useBreadcrumbLinks()

// Provide the business area to views
provide('businessArea', '' + props.businessArea?.id)
</script>

<style lang="postcss">
.hero-image img {
  @apply max-h-[500px] object-cover;
}
</style>
